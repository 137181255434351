import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [telegramId, setTelegramId] = useState(null);
  const [userName, setUserName] = useState(''); 
  const [data, setData] = useState(null);
  const [history, setHistory] = useState([]);  // Для истории действий
  const [error, setError] = useState(null);

  useEffect(() => {
    const telegram = window.Telegram?.WebApp;
    const userId = telegram?.initDataUnsafe?.user?.id;
    const firstName = telegram?.initDataUnsafe?.user?.first_name;
    const lastName = telegram?.initDataUnsafe?.user?.last_name;

    if (userId) {
      setTelegramId(userId);
      setUserName(`${firstName} ${lastName}`);
      fetchData(userId);
      fetchHistory(userId);  // Получаем историю действий
      telegram.expand();
    }
  }, []);

  function fetchData(userId) {
    setError(null);

    fetch('https://www.yavdinamike.ru/api/player/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ telegram_id: userId }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Ошибка HTTP: ${response.status}`);
        }
        return response.json();
      })
      .then(result => {
        setData(result);
        console.log('Данные обновлены:', result);
      })
      .catch(error => {
        setError(error.message || 'Ошибка при загрузке данных');
        console.error('Ошибка при получении данных:', error);
      });
  }

  function fetchHistory(userId) {
    fetch('https://www.yavdinamike.ru/api/history/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ telegram_id: userId, days: 7 }), // Запрашиваем за последние 7 дней
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Ошибка HTTP: ${response.status}`);
        }
        return response.json();
      })
      .then(result => {
        setHistory(result.action_history);
        console.log('История действий:', result.action_history);
      })
      .catch(error => {
        setError(error.message || 'Ошибка при получении истории действий');
        console.error('Ошибка при получении истории действий:', error);
      });
  }

  function performAction(actionId) {
    fetch('https://www.yavdinamike.ru/api/action/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        telegram_id: telegramId,
        action_id: actionId,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Ошибка HTTP: ${response.status}`);
        }
        return response.json();
      })
      .then(result => {
        setData(result);
        console.log('Действие выполнено, новые данные:', result);
      })
      .catch(error => {
        setError(error.message || 'Ошибка при выполнении действия');
        console.error('Ошибка при выполнении действия:', error);
      });
  }

  if (!telegramId) {
    return null; // Не рендерим ничего, если нет Telegram ID
  }

  return (
    <div className="container">
      <h2 className="welcome-message">Трекер выздоровления</h2>
      <p className="description">
        Позволяет отслеживать регулярные действия<br />Действуй и возвращайся!
      </p>

      {error && <p className="error">Ошибка: {error}</p>}

      {telegramId && data ? (
        <div className="content">
          <h2>Сегодня твой день, {userName}</h2>
          <div className="counters">
            <p>Всего выполнено действий: {data.stats?.total_actions || 0}</p>
          </div>

          <div className="actions">
            {data.available_actions.length > 0 ? (
              data.available_actions.map(action => (
                <button className="action-button" key={action.id} onClick={() => performAction(action.id)}>
                  {action.name}
                </button>
              ))
            ) : (
              <p>Сегодня все действия выполнены, так держать!</p>
            )}
          </div>

          {/* Добавляем раздел с историей */}
	<div className="history-container">
          <div className="history section">
            <h3>История твоих действий</h3>
            {history.length > 0 ? (
              history.map(day => (
                <div key={day.date}>
                  <h4>{day.date}</h4>
                  <ul>
                    {day.actions.map(action => (
                      <li key={action.action_name} className={action.completed ? 'completed' : ''}>
                        {action.action_name}
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            ) : (
              <p>Пока нет данных об истории действий</p>
            )}
          </div>
	 </div>
        </div>
      ) : (
        null
      )}
    </div>
  );
}

export default App;
